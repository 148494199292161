import React, { useEffect, FC } from 'react'
import * as Sentry from '@sentry/node'
import env from './env'
import { useJWTRole, useJWTTokenPayload } from '@freec/corelib/hooks/JWTHooks'
import { useCurrentUser } from '@freec/corelib/hooks/CurrentUserHooks'
const intallCrisp = (onloaData: () => void, tokenId: string | number | undefined) => {
  const id = 'crisp-lib'
  const script = () => {
    const script = document.createElement('script')
    script.async = true
    script.type = 'text/javascript'
    script.src = 'https://client.crisp.chat/l.js'
    script.id = id
    document.head.appendChild(script)
    script.addEventListener('load', onloaData, false)
    return script
  }

  if (document.getElementById(id)) {
    window.CRISP_TOKEN_ID = tokenId
    window.$crisp.push(['do', 'session:reset'])
    onloaData()
    window.$crisp.push(['do', 'chat:show'])
    return
  }
  const emptyArray = []
  window.$crisp = emptyArray
  window.CRISP_WEBSITE_ID = env.PUBLIC_CRISP_WEBSITE_ID
  document.head.appendChild(script())
}
const IGNORE_PATH = ['^/chat']
const isIgnore = () => IGNORE_PATH.find((path) => location.pathname.match(path))
const CrispChat: FC = () => {
  const allowUserType = env.PUBLIC_ENABLE_LIVE_CHAT_USER_TYPE?.split(',').map((stri) => parseInt(stri))
  const currentRole = useJWTRole()
  const jwtokenPayload = useJWTTokenPayload()
  const [currentUser] = useCurrentUser()
  const configType = { candidate: 1, company: 2, consultant: 3, anonymous: 4 }[currentRole] || 4

  useEffect(() => {
    if (jwtokenPayload && jwtokenPayload.user_type !== undefined && allowUserType.includes(configType) && !isIgnore()) {
      if (currentUser) {
        const onLoad = () => {
          try {
            window.$crisp.push(['set', 'user:email', currentUser?.email])
            window.$crisp.push(['set', 'user:nickname', currentUser?.full_name])
            window.$crisp.push(['set', 'user:phone', currentUser?.phone_number])
            window.$crisp.push(['set', 'user:avatar', currentUser?.photo_url])
            window.$crisp.push(['set', 'session:segments', [[currentRole === 'company' ? 'recruiter' : currentRole, 'web-chat']]])
            window.$crisp.push(['set', 'session:data', [[['freec_user_id', currentUser?.id]]]])
          } catch (error) {
            Sentry.captureException(error)
          }
        }

        intallCrisp(onLoad, currentUser?.id)
      } else {
        if (window.$crisp) {
          window.$crisp.push(['do', 'chat:close'])
        }
      }
    } else {
      // anonymous user goes here
      if (allowUserType.includes(4) && !isIgnore()) {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        intallCrisp(() => {}, undefined)
      } else {
        if (window.$crisp) {
          window.$crisp.push(['do', 'chat:hide'])
        }
      }
    }
    return () => {
      // consolog aim to destroy
    }
  }, [jwtokenPayload?.user_type, currentUser])
  return <></>
}
export default CrispChat
