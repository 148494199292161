import css from 'styled-jsx/css'

export const SidebarStyle = css.global`
  .sidebar {
    display: none;
  }

  @media screen and (max-width: 1023.98px) {
    .sidebar {
      position: fixed;
      z-index: 999;
      width: 100%;
      background: #fff;
      min-height: var(--layout-header-height);
      display: flex;
      align-items: center;
    }

    .sidebar .none-shadow {
      box-shadow: none;
    }

    .sidebar .logo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding-left: 16px;
    }

    .sidebar .logo .header-logo-img {
      height: 34px;
    }

    .sidebar .logo .btn-link {
      margin-right: 64px;
      background-color: #fff;
      padding: 0;
      border: none;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #595959;
    }
    .sidebar .logo .mr-0 {
      margin-right: 0;
    }

    .sidebar #nav-icon {
      position: absolute;
      right: 5%;
      transition: 0.5s ease-in-out;
      cursor: pointer;
      z-index: 555;
      background-color: transparent;
      border-color: transparent;
      border-radius: 50%;
      width: 34px;
      padding: 7px 0px 5px 4px;
      background-color: #fff;
    }

    .sidebar #nav-icon:focus {
      outline: none;
    }

    .sidebar #nav-icon .wrap-icon {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 16px;
    }

    .sidebar #nav-icon .wrap-icon span {
      width: 22px;
      height: 2px;
      display: block;
      background: #262626;
      transition: all 0.4s ease-in-out;
    }

    .sidebar #nav-icon.open span:nth-child(1) {
      transform: translate(0, 6px) rotate(45deg);
      background-color: #262626;
    }

    .sidebar #nav-icon.open span:nth-child(2) {
      opacity: 0;
    }

    .sidebar #nav-icon.open span:nth-child(3) {
      transform: translate(0, -8px) rotate(-45deg);
      background-color: #262626;
    }
  }

  body .btn-close-sidebar {
    border: none;
    box-shadow: unset;
    /* font-size: 20px; */
    padding: 0;
    height: 24px;
  }

  body .ant-drawer-open {
    z-index: 1000;
  }

  body .ant-drawer-body {
    display: flex;
    flex-direction: column;
    padding: 0;
  }
  .sidebar-item {
    padding: 13px 16px;
  }
  .sidebar-item.register-row {
    padding-top: 16px;
  }
  .sidebar-item .btn-link {
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    box-shadow: none;
  }
  .sidebar-item .btn-link svg {
    width: 20px;
    height: 20px;
  }
  body .ant-drawer-footer {
    padding: 0;
  }
  .btn-employer {
    background-color: #f9fafc;
    padding: 16px;
    border: none;
    text-align: left;
    box-shadow: none;
    width: 100%;
  }
  .btn-employer .icon-left {
    margin-right: 12px;
  }

  .btn-employer .title {
    font-size: 14px;
    color: var(--primary);
    font-weight: 600;
    text-transform: uppercase;
  }

  .btn-employer .sub-title {
    font-size: 12px;
    color: #262626;
  }

  .btn-employer .icon-right {
    margin-left: auto;
  }

  .btn-link {
    background-color: #fff;
    padding: 0;
    border: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #141921 !important;
    display: flex;
    align-items: center;
    box-shadow: none;
  }
  .btn-link a {
    color: #141921;
  }
  .btn-login {
    font-size: 14px;
    font-weight: 600;
    color: #141921;
    padding: 9px 24px;
    border-radius: 4px;
    border: solid 1px #d1d7dc;
    background-color: #fff;
    margin-right: 16px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .btn-register {
    font-size: 14px;
    font-weight: 500;
    color: #fff !important;
    padding: 9px 24px;
    border-radius: 4px;
    border: solid 1px var(--primary);
    background-color: var(--primary);
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .w-50 {
    width: 50%;
  }
  .mr-3 {
    margin-right: 16px;
  }
  .sidebar-divider {
    background: #e8e8e8;
    height: 1px;
    width: 100%;
    margin: 8px 0;
  }
  .account-menubar-link {
    margin-left: 4px !important;
    color: var(--primary);
    text-decoration: underline;
  }
  .account-menubar-info {
    display: flex;
    padding: 16px 16px 8px 16px;
    align-items: center;
  }
  .account-menubar-info .avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 5px;
    border: solid 1px #f8f8f8;
  }
  .account-menubar-info .avatar-user {
    max-width: 60px;
  }
  .account-avatar {
    margin-right: 12px;
  }
  .account-menubar__name {
    font-size: 14px;
    color: #141921;
    font-weight: 600;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: break-spaces;
    word-break: break-all;
  }
  .account-menubar__job-title {
    font-size: 12px;
    color: #141921;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: break-spaces;
    word-break: break-all;
  }
  .account-menubar__email {
    font-size: 12px;
    color: #7a7c82;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: break-spaces;
    word-break: break-all;
  }
  .account-setting-header {
    align-items: center;
  }
  .account-setting-header .btn-close-sidebar {
    border: none;
    box-shadow: unset;
    font-size: 20px;
    padding: 0;
    background-color: transparent;
    margin-right: 16px;
    display: flex;
    align-items: center;
  }
  .account-setting-header .setting-title {
    font-size: 16px;
    font-weight: 600;
  }
  .register-wrapper {
    margin-right: 74px;
  }
  body .ant-drawer-body .group-link p {
    margin-bottom: 24px;
  }
  body .ant-drawer-body .view-company-name {
    display: flex;
    flex-direction: column;
  }

  body .ant-drawer-body .view-company-name .label-career-site {
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #595959;
  }

  body .ant-drawer-body .view-company-name .label-company-name {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #595959;
    margin-top: 4px;
  }

  body .ant-drawer-body .view-company-name .label-company-name:hover {
    color: var(--primary);
  }

  body .ant-drawer-body .group-button .btn-log-out {
    text-align: left;
    padding: 0;
    border: none;
    box-shadow: unset;
    font-size: 18px;
    line-height: 24px;
    color: #595959;
    font-weight: 500;
  }
  body .ant-drawer-body .group-button .btn-log-out .btn-log-out-text {
    margin-left: 0;
  }
  body .ant-drawer-body .group-button-before {
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  body .ant-drawer-body .group-button-employer {
    height: 80%;
  }

  body .ant-drawer-body .group-button .btn-employer {
    padding: 0;
    border: none;
    text-align: left;
    box-shadow: none;
    margin-top: 30px;
  }
  body .ant-drawer-body .group-button .btn-employer .title {
    font-size: 20px;
    color: var(--primary);
    font-weight: 500;
  }
  body .ant-drawer-body .group-button .btn-employer .sub-title {
    font-size: 14px;
    color: #8c8c8c;
  }
  body .ant-drawer-body .tag-beta {
    display: inline-block;
    padding: 2px 5px;
    border-radius: 2px;
    background-color: #ff4d4f;
    margin-left: 8px !important;
    font-size: 8px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    text-transform: uppercase;
  }
  body .ant-drawer-body .ant-divider {
    background-color: #e8e8e8 !important;
  }
  body .ant-drawer-body .download {
    margin-top: 53px;
  }

  body .ant-drawer-body .download .logo-download {
    height: 36px;
  }

  body .ant-drawer-body .download .logo-download img {
    width: 100%;
  }

  body .ant-drawer-body .icon {
    margin-right: 12px;
    color: #6c7b8d;
  }

  body .sidebar-collapse {
    margin-bottom: 24px;
  }

  body .sidebar-collapse .ant-collapse-item {
    border: none;
  }

  body .sidebar-collapse .ant-collapse-header {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #595959 !important;
    padding-left: 0 !important;
    background-color: #fff !important;
    padding-bottom: 24px;
  }

  body .sidebar-collapse .sub-menu {
    font-size: 16px;
    line-height: 24px;
    color: #595959;
    font-weight: normal;
    text-transform: lowercase;
  }
  body .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
    background-color: #fff;
  }

  body .sidebar-lang {
    display: flex;
    align-items: center;
  }

  body .sidebar-lang .btn-lang {
    flex: 1;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    box-shadow: none;
    opacity: 0.3;
    padding: 0;
  }
  body .sidebar-lang span {
    font-size: 14px;
    color: #141921;
    margin-left: 8px;
    font-weight: 600;
  }

  body .sidebar-lang .activeLang {
    opacity: 1;
  }
  .divider-language {
    width: 1px;
    background-color: #d1d7dc;
    height: 14px;
    margin: 0 16px;
  }
  body .ant-drawer-header {
    border-bottom: 1px solid #e8e8e8;
    padding: 20px 16px;
  }

  .sidebar .view-company-name {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 64px;
  }

  .sidebar .view-company-name .label-career-site {
    font-family: Roboto;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #595959;
  }

  .sidebar .view-company-name .label-company-name {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #595959;
    margin-top: 4px;
  }

  .sidebar .view-company-name .label-company-name:hover {
    color: var(--primary);
  }
`
