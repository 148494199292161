import { useEffect } from 'react'

import {
  COOKIES_NAME_REFERRAL_ID,
  COOKIES_NAME_UTM_CAMPAIGN,
  COOKIES_NAME_UTM_MEDIUM,
  COOKIES_NAME_UTM_SOURCE,
  setCookie
} from '../utilities/Cookies'
import { useAppRouter } from './UseAppRouter'

export const useReferralColector = (): void => {
  const router = useAppRouter()
  useEffect(() => {
    const referralId = router.query['referral-id']
    if (referralId) {
      setCookie(COOKIES_NAME_REFERRAL_ID, referralId as string)
    }
  }, [router])
}
export const useUTMCollector = (): void => {
  const router = useAppRouter()
  useEffect(() => {
    const utmSource = router.query['utm_source']
    const utmCampaign = router.query['utm_campaign']
    const utmMedium = router.query['utm_medium']
    if (utmSource) {
      setCookie(COOKIES_NAME_UTM_SOURCE, utmSource as string, 1000)
    }
    if (utmCampaign) {
      setCookie(COOKIES_NAME_UTM_CAMPAIGN, utmCampaign as string, 1000)
    }
    if (utmMedium) {
      setCookie(COOKIES_NAME_UTM_MEDIUM, utmMedium as string, 1000)
    }
  }, [router])
}
