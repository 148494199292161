import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

// import en_translation from '../../../src/locales/en/en.json'
import en_common from '../../../src/locales/en-US/common.json'
// import en_validate from '../../../src/locales/en/validate.json'
// import en_admin from '../../../src/locales/en/admin.json'

// import vi_translation from '../../../src/locales/vi/vi.json'
import vi_common from '../../../src/locales/vi-VN/common.json'
// import vi_validate from '../../../src/locales/vi/validate.json'
// import vi_page_login from '../../../src/locales/vi/page_login.json'
// import vi_admin from '../../../src/locales/vi/admin.json'

// import jpTranslation from '../../../src/locales/jp/jp.json'

import en_button from '../../../src/locales/en-US/button.json'
import en_label from '../../../src/locales/en-US/label.json'
import en_form from '../../../src/locales/en-US/form.json'
import en_enrich_modal from '../../../src/locales/en-US/enrich_modal.json'
import en_search from '../../../src/locales/en-US/search.json'
// import en_notification from '../../../src/locales/en-US/notification.json'
import en_empty from '../../../src/locales/en-US/empty.json'
import en_notification from '../../../src/locales/en-US/notification.json'
import en_time_label from '../../../src/locales/en-US/label_time.json'
import vi_time_label from '../../../src/locales/vi-VN/label_time.json'
// import en_empty from '../../../src/locales/en-US/empty.json'
// import en_activity from '../../../src/locales/en-US/activity.json'
// import en_email_template from '../../../src/locales/en-US/email_template.json'
// import en_premium_company from '../../../src/locales/en-US/premium_company.json'
/**
 * Meta
 */
import en_meta from '../../../src/locales/en-US/meta.json'
import en_meta_employer_product from '../../../src/locales/en-US/meta_employer_product.json'
// import en_status from '../../../src/locales/en-US/status.json'
/**
 * Page
 */
// import en_page_account_invite_team from '../../../src/locales/en-US/page_account_invite_team.json'
import en_page_account_change_password from '../../../src/locales/en-US/page_account_change_password.json'
import en_page_account_referral from '../../../src/locales/en-US/page_account_referral.json'
import en_page_my_profile from '../../../src/locales/en-US/page_my_profile.json'
import en_page_download_app from '../../../src/locales/en-US/page_download_app.json'
import en_page_my_jobs from '../../../src/locales/en-US/page_my_jobs.json'
import en_page_home from '../../../src/locales/en-US/page_home.json'
// import en_page_home_business_tag from '../../../src/locales/en-US/page_home_business_tag.json'
import en_page_employer_product from '../../../src/locales/en-US/page_employer_product.json'
import en_page_employer_headhunt_service from '../../../src/locales/en-US/page_employer_headhunt_service.json'

// import en_page_employer_candidates from '../../../src/locales/en-US/page_employer_candidates.json'
// import en_page_employer_jobs_create from '../../../src/locales/en-US/page_employer_jobs_create.json'
// import en_page_employer_jobs_id_applicants from '../../../src/locales/en-US/page_employer_jobs_id_applicants.json'
// import en_page_employer_jobs_id_detail from '../../../src/locales/en-US/page_employer_jobs_id_detail.json'
// import en_page_employer_jobs_id from '../../../src/locales/en-US/page_employer_jobs_id.json'
// import en_page_employer_jobs from '../../../src/locales/en-US/page_employer_jobs.json'

// import en_page_employer_preview_job from '../../../src/locales/en-US/page_employer_preview_job.json'
import en_page_employer_contact from '../../../src/locales/en-US/page_employer_contact.json'
import en_page_register from '../../../src/locales/en-US/page_register.json'
import en_page_login from '../../../src/locales/en-US/page_login.json'
import en_page_forgot_password from '../../../src/locales/en-US/page_forgot_password.json'
import en_page_jobs from '../../../src/locales/en-US/page_jobs.json'
import en_page_public_job_detail from '../../../src/locales/en-US/page_public_jobs_detail.json'
// import en_page_candidates_first_use from '../../../src/locales/en-US/page_candidates_first_use.json'
import en_page_companies from '../../../src/locales/en-US/page_companies.json'
// import en_page_consultants from '../../../src/locales/en-US/page_consultants.json'
import en_job_alert from '../../../src/locales/en-US/job_alert.json'
// import en_skills from '../../../src/locales/en-US/skills.json'
// import en_talent_pool from '../../../src/locales/en-US/talent_pool.json'
import en_experience from '../../../src/locales/en-US/experience.json'
import en_education from '../../../src/locales/en-US/education.json'
import en_certificate from '../../../src/locales/en-US/certificate.json'
import en_work_reference from '../../../src/locales/en-US/work_reference.json'
import en_job_preference from '../../../src/locales/en-US/job_preference.json'
import en_card from '../../../src/locales/en-US/card.json'
// import en_offer from '../../../src/locales/en-US/offer.json'
// import en_modal from '../../../src/locales/en-US/modal.json'
import en_header from '../../../src/locales/en-US/header.json'
import en_footer from '../../../src/locales/en-US/footer.json'
import en_public_company from '../../../src/locales/en-US/public_company.json'
import en_page_manage_cv from '../../../src/locales/en-US/page_manage_cv.json'

import en_public_key from '../../../src/locales/en-US/public_key.json'
import en_page_lp_cv from '../../../src/locales/en-US/page_lp_cv.json'
import en_page_lp_cv_meta from '../../../src/locales/en-US/page_lp_cv_meta.json'
import en_keywords from '../../../src/locales/en-US/keywords.json'
import en_quick_apply from '../../../src/locales/en-US/quick_apply.json'
import en_chat from '../../../src/locales/en-US/chat.json'
import en_l4j from '../../../src/locales/en-US/l4j.json'
import en_follow_company from '../../../src/locales/en-US/follow_company.json'

import vi_button from '../../../src/locales/vi-VN/button.json'
import vi_label from '../../../src/locales/vi-VN/label.json'
import vi_form from '../../../src/locales/vi-VN/form.json'
import vi_enrich_modal from '../../../src/locales/vi-VN/enrich_modal.json'
import vi_search from '../../../src/locales/vi-VN/search.json'
// import vi_notification from '../../../src/locales/vi-VN/notification.json'
import vi_empty from '../../../src/locales/vi-VN/empty.json'
import vi_notification from '../../../src/locales/vi-VN/notification.json'
// import vi_empty from '../../../src/locales/vi-VN/empty.json'
// import vi_activity from '../../../src/locales/vi-VN/activity.json'
// import vi_email_template from '../../../src/locales/vi-VN/email_template.json'
// import vi_premium_company from '../../../src/locales/vi-VN/premium_company.json'
import vi_meta from '../../../src/locales/vi-VN/meta.json'
import vi_meta_employer_product from '../../../src/locales/vi-VN/meta_employer_product.json'
// import vi_status from '../../../src/locales/vi-VN/status.json'
// import vi_page_account_invite_team from '../../../src/locales/vi-VN/page_account_invite_team.json'
import vi_page_account_change_password from '../../../src/locales/vi-VN/page_account_change_password.json'
import vi_page_account_referral from '../../../src/locales/vi-VN/page_account_referral.json'
import vi_page_my_profile from '../../../src/locales/vi-VN/page_my_profile.json'
import vi_page_download_app from '../../../src/locales/vi-VN/page_download_app.json'
import vi_page_my_jobs from '../../../src/locales/vi-VN/page_my_jobs.json'
import vi_page_home from '../../../src/locales/vi-VN/page_home.json'
// import vi_page_home_business_tag from '../../../src/locales/vi-VN/page_home_business_tag.json'
import vi_page_employer_product from '../../../src/locales/vi-VN/page_employer_product.json'
import vi_page_employer_headhunt_service from '../../../src/locales/vi-VN/page_employer_headhunt_service.json'
// import vi_page_employer_candidates from '../../../src/locales/vi-VN/page_employer_candidates.json'
// import vi_page_employer_jobs_create from '../../../src/locales/vi-VN/page_employer_jobs_create.json'
// import vi_page_employer_jobs_id_applicants from '../../../src/locales/vi-VN/page_employer_jobs_id_applicants.json'
// import vi_page_employer_jobs_id_detail from '../../../src/locales/vi-VN/page_employer_jobs_id_detail.json'
// import vi_page_employer_jobs_id from '../../../src/locales/vi-VN/page_employer_jobs_id.json'
// import vi_page_employer_jobs from '../../../src/locales/vi-VN/page_employer_jobs.json'
// import vi_page_employer_preview_job from '../../../src/locales/vi-VN/page_employer_preview_job.json'
import vi_page_employer_contact from '../../../src/locales/vi-VN/page_employer_contact.json'
import vi_page_register from '../../../src/locales/vi-VN/page_register.json'
import vi_page_login from '../../../src/locales/vi-VN/page_login.json'
import vi_page_forgot_password from '../../../src/locales/vi-VN/page_forgot_password.json'
import vi_page_jobs from '../../../src/locales/vi-VN/page_jobs.json'
import vi_page_public_job_detail from '../../../src/locales/vi-VN/page_public_job_detail.json'
import vi_page_companies from '../../../src/locales/vi-VN/page_companies.json'
// import vi_page_candidates_first_use from '../../../src/locales/vi-VN/page_candidates_first_use.json'
// import vi_page_consultants from '../../../src/locales/vi-VN/page_consultants.json'
import vi_job_alert from '../../../src/locales/vi-VN/job_alert.json'
// import vi_skills from '../../../src/locales/vi-VN/skills.json'
// import vi_talent_pool from '../../../src/locales/vi-VN/talent_pool.json'
import vi_experience from '../../../src/locales/vi-VN/experience.json'
import vi_education from '../../../src/locales/vi-VN/education.json'
import vi_certificate from '../../../src/locales/vi-VN/certificate.json'
import vi_work_reference from '../../../src/locales/vi-VN/work_reference.json'
import vi_job_preference from '../../../src/locales/vi-VN/job_preference.json'
import vi_card from '../../../src/locales/vi-VN/card.json'
// import vi_offer from '../../../src/locales/vi-VN/offer.json'
// import vi_modal from '../../../src/locales/vi-VN/modal.json'
import vi_header from '../../../src/locales/vi-VN/header.json'
import vi_footer from '../../../src/locales/vi-VN/footer.json'
import vi_public_company from '../../../src/locales/vi-VN/public_company.json'
import vi_page_manage_cv from '../../../src/locales/vi-VN/page_manage_cv.json'

import vi_public_key from '../../../src/locales/vi-VN/public_key.json'
import vi_page_lp_cv from '../../../src/locales/vi-VN/page_lp_cv.json'
import vi_page_lp_cv_meta from '../../../src/locales/vi-VN/page_lp_cv_meta.json'
import vi_keywords from '../../../src/locales/vi-VN/keywords.json'
import vi_quick_apply from '../../../src/locales/vi-VN/quick_apply.json'
import vi_chat from '../../../src/locales/vi-VN/chat.json'
import vi_l4j from '../../../src/locales/vi-VN/l4j.json'
import vi_follow_company from '../../../src/locales/vi-VN/follow_company.json'

const resources = {
  en: {
    // Common translation
    button: en_button,
    label: en_label,
    form: en_form,
    enrich_modal: en_enrich_modal,
    search: en_search,
    // notification: en_notification,
    empty: en_empty,
    notification: en_notification,
    // empty: en_empty,
    // activity: en_activity,
    // email_template: en_email_template,
    // premium_company: en_premium_company,
    meta: en_meta,
    meta_employer_product: en_meta_employer_product,
    // status: en_status,

    // Pages
    // page_account_invite_team: en_page_account_invite_team,
    page_account_change_password: en_page_account_change_password,
    page_account_referral: en_page_account_referral,
    page_my_profile: en_page_my_profile,
    page_download_app: en_page_download_app,
    page_my_jobs: en_page_my_jobs,
    page_home: en_page_home,
    // page_home_business_tag: en_page_home_business_tag,
    page_employer_product: en_page_employer_product,
    page_employer_headhunt_service: en_page_employer_headhunt_service,

    // page_employer_candidates: en_page_employer_candidates,
    // page_employer_jobs_create: en_page_employer_jobs_create,
    // page_employer_jobs_id_applicants: en_page_employer_jobs_id_applicants,
    // page_employer_jobs_id_detail: en_page_employer_jobs_id_detail,
    // page_employer_jobs_id: en_page_employer_jobs_id,
    // page_employer_jobs: en_page_employer_jobs,

    page_companies: en_page_companies,

    // page_employer_preview_job: en_page_employer_preview_job,
    page_employer_contact: en_page_employer_contact,
    page_register: en_page_register,
    page_login: en_page_login,
    page_forgot_password: en_page_forgot_password,
    page_jobs: en_page_jobs,
    page_public_job_detail: en_page_public_job_detail,
    // page_candidates_first_use: en_page_candidates_first_use,
    // page_consultants: en_page_consultants,
    page_manage_cv: en_page_manage_cv,
    page_lp_cv: en_page_lp_cv,
    page_lp_cv_meta: en_page_lp_cv_meta,

    // Components
    job_alert: en_job_alert,
    // skills: en_skills,
    // talent_pool: en_talent_pool,
    experience: en_experience,
    education: en_education,
    certificate: en_certificate,
    work_reference: en_work_reference,
    job_preference: en_job_preference,
    card: en_card,
    // offer: en_offer,
    // modal: en_modal,
    header: en_header,
    footer: en_footer,
    public_company: en_public_company,

    // Public Translation
    public_key: en_public_key,
    keywords: en_keywords,
    time_label: en_time_label,
    common: en_common,
    quick_apply: en_quick_apply,
    chat: en_chat,
    l4j: en_l4j,
    follow_company: en_follow_company
  },
  vi: {
    // Common translation
    button: vi_button,
    label: vi_label,
    form: vi_form,
    enrich_modal: vi_enrich_modal,
    search: vi_search,
    // notification: vi_notification,
    empty: vi_empty,
    notification: vi_notification,
    // empty: vi_empty,
    // premium_company: vi_premium_company,
    meta: vi_meta,
    meta_employer_product: vi_meta_employer_product,
    // status: vi_status,
    page_account_change_password: vi_page_account_change_password,
    page_account_referral: vi_page_account_referral,
    page_my_profile: vi_page_my_profile,
    page_download_app: vi_page_download_app,
    page_my_jobs: vi_page_my_jobs,
    page_home: vi_page_home,
    // page_home_business_tag: vi_page_home_business_tag,
    page_employer_product: vi_page_employer_product,
    page_employer_headhunt_service: vi_page_employer_headhunt_service,
    page_companies: vi_page_companies,
    page_employer_contact: vi_page_employer_contact,
    page_register: vi_page_register,
    page_login: vi_page_login,
    page_forgot_password: vi_page_forgot_password,
    page_jobs: vi_page_jobs,
    page_public_job_detail: vi_page_public_job_detail,
    // page_candidates_first_use: vi_page_candidates_first_use,
    // page_consultants: vi_page_consultants,
    page_manage_cv: vi_page_manage_cv,
    page_lp_cv: vi_page_lp_cv,
    page_lp_cv_meta: vi_page_lp_cv_meta,

    // // Components
    job_alert: vi_job_alert,
    // skills: vi_skills,
    // talent_pool: vi_talent_pool,page_my_jobs
    experience: vi_experience,
    education: vi_education,
    certificate: vi_certificate,
    work_reference: vi_work_reference,
    job_preference: vi_job_preference,
    card: vi_card,
    // offer: vi_offer,
    // modal: vi_modal,
    header: vi_header,
    footer: vi_footer,
    public_company: vi_public_company,

    // Public Translation
    public_key: vi_public_key,
    keywords: vi_keywords,
    time_label: vi_time_label,
    common: vi_common,
    quick_apply: vi_quick_apply,
    chat: vi_chat,
    l4j: vi_l4j,
    follow_company: vi_follow_company
  }
}
let currentLanguage: string
export const getCurrentLanguage = (): string => currentLanguage
export default function createI18n(lng: string): void {
  currentLanguage = lng
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources,
      lng,
      fallbackLng: false,
      keySeparator: false, // we do not use keys in form messages.welcome
      interpolation: {
        escapeValue: false // react already safes from xss
      }
    })
}
