import React, { FC, useEffect } from 'react'
import Head from 'next/head'
import { useAmp } from 'next/amp'
import { useAppRouter } from '@freec/corelib/hooks/UseAppRouter'
import { setOn401, setOn403, useRoutingCollector } from '@freec/corelib/utilities/Client'
import { LINK_URL_LOGIN } from 'utilities/URL'
import { useReferralColector, useUTMCollector } from '@freec/corelib/hooks/UseCollector'
import CrispChat from 'utilities/CrispChat'
import { VariableStyle } from '@freec/corelib/style/utilities/variable.style'
import { ResetStyle } from '@freec/corelib/style/base/reset.style'
import { TextStyle } from '@freec/corelib/style/utilities/text.style'
import { FontsStyle } from '@freec/corelib/style/base/fonts.style'
import { ColorsStyle } from '@freec/corelib/style/base/colors.style'
import { MixinsStyle } from '@freec/corelib/style/utilities/mixins.style'
import { SidebarStyle } from '@freec/corelib/style/layout/sidebar.style'
import { AntdStyle } from '@freec/corelib/style/utilities/antd.style'
import 'nprogress/nprogress.css'
import env from 'utilities/env'
import { DefaultLayoutCompanyStyle } from 'styles/components/styled/DefaultLayoutCompany.style'
import { IssueStyle } from '@freec/corelib/style/base/issue.style'
import EnrichModal from 'modules/enrich'
import GleapWidget from 'utilities/GleapWidget'
import { getClient } from '@freec/optimal-pageutillities/getIsServer'

const DefaultLayout: FC = ({ children }) => {
  const router = useAppRouter()
  useEffect(() => {
    setOn401(() => router.push(LINK_URL_LOGIN))
    setOn403(() => router.push(LINK_URL_LOGIN))
  }, [])
  useReferralColector()
  useUTMCollector()

  return (
    <>
      <Head>
        <link rel="apple-touch-icon" href={`${env.PUBLIC_APP_DOMAIN}/img/apple-touch-icon.png`} />
        {env.PUBLIC_NO_INDEX === 'true' && !router.query['allow-index'] === undefined && <meta name="robots" content="noindex" />}
        <title>freeC - Website tuyển dụng thông minh</title>
        <link rel="shortcut icon" type="image/png" sizes="32x32" href="/favicon_32x32.png"></link>
        <link rel="shortcut icon" type="image/png" sizes="16x16" href="/favicon_16x16.png"></link>
        <link rel="preconnect" href="https://fonts.gstatic.com"></link>
        <link href="https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap" rel="stylesheet" media="print" />
        {env.PUBLIC_GTM_KEY && (
          <script
            dangerouslySetInnerHTML={{
              __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${env.PUBLIC_GTM_KEY}');`
            }}
          />
        )}
      </Head>
      {env.PUBLIC_GTM_KEY && (
        <noscript>
          <iframe
            src={`https://www.googletagmanager.com/ns.html?id=${env.PUBLIC_GTM_KEY}`}
            height="0"
            width="0"
            style={{ display: 'none', visibility: 'hidden' }}></iframe>
        </noscript>
      )}
      {env.PUBLIC_ENABLE_LIVE_CHAT === 'true' && <CrispChat />}
      {env.NEXT_PUBLIC_ENABLE_GLEAP && getClient() && <GleapWidget apiKey={env.NEXT_PUBLIC_ENABLE_GLEAP} />}
      <EnrichModal />
      {children}
    </>
  )
}

export const LayoutProvider: FC = ({ children }) => {
  const router = useAppRouter()
  const isAmp = useAmp()
  useRoutingCollector(router)
  // useCollectHistory()
  const topStyle = (
    <>
      <style jsx global>
        {VariableStyle}
      </style>
      <style jsx global>
        {ResetStyle}
      </style>
      <style jsx global>
        {IssueStyle}
      </style>
    </>
  )
  const bottomStyle = (
    <>
      <style jsx global>
        {TextStyle}
      </style>
      <style jsx global>
        {FontsStyle}
      </style>
      <style jsx global>
        {ColorsStyle}
      </style>
      <style jsx global>
        {MixinsStyle}
      </style>
      <style jsx global>
        {SidebarStyle}
      </style>
      <style jsx global>
        {DefaultLayoutCompanyStyle}
      </style>
    </>
  )
  return (
    <>
      {!isAmp && (
        <style jsx global>
          {AntdStyle}
        </style>
      )}
      {topStyle}
      {bottomStyle}
      <DefaultLayout>{children}</DefaultLayout>
    </>
  )
}

export default DefaultLayout
