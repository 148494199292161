import FreecProvider, { ServerBunble, ServerService } from '@freec/corelib/components/FreecProvider'
import { getPathSpecificLanguage } from '@freec/corelib/components/share/AppLink'
import { useNProgress } from '@freec/corelib/hooks/NProgress'
import { DEFAULT_LOCALE } from '@freec/corelib/utilities/Constant'
import { COOKIES_NAME_LANGUAGE, getCookie } from '@freec/corelib/utilities/Cookies'
import { getIsServer } from '@freec/corelib/utilities/SSR'
import { app_GetOS, app_IsMobile } from '@freec/corelib/utilities/_appUtilities'
import { LayoutProvider } from 'layout/DefaultLayout'
import createI18n from 'locales/i18n'
import { NextPageContext } from 'next'
import { AppProps } from 'next/app'
import React from 'react'
import * as Sentry from '@sentry/node'
import { NextRequestType } from '@freec/corelib/type/Common'
import cookies from 'next-cookies'

function FreecPublicPageWrapper({
  Component,
  serverBundle,
  serverService,
  ...pageProps
}: AppProps & { serverService: ServerService; serverBundle: ServerBunble }) {
  const realPath = getIsServer() ? serverService.originalUrl : window.location.pathname
  const realLocale =
    getPathSpecificLanguage(realPath) ||
    (getIsServer() ? serverService.getCookie(COOKIES_NAME_LANGUAGE) : getCookie(COOKIES_NAME_LANGUAGE)) ||
    DEFAULT_LOCALE
  createI18n(realLocale)
  useNProgress()
  return (
    <FreecProvider serverBundle={serverBundle} serverService={serverService}>
      <LayoutProvider>
        {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          <Component {...pageProps} />
        }
      </LayoutProvider>
    </FreecProvider>
  )
}
FreecPublicPageWrapper.getInitialProps = async (appContext) => {
  const req = appContext.ctx.req as NextRequestType
  const getCookies = (key) => (appContext.ctx.req ? appContext.ctx.req.headers.cookie && cookies(appContext.ctx)[key] : getCookie(key))
  const locale = getCookies(COOKIES_NAME_LANGUAGE) || DEFAULT_LOCALE
  const serverBundle = getIsServer() && { isMobile: app_IsMobile(req) }
  const serverService: ServerService = getIsServer() && {
    getCookie: getCookies,
    originalUrl: req.url,
    isMobile: !!app_IsMobile(req),
    agentDetect: {
      isMobile: app_IsMobile(req),
      os: app_GetOS(req)
    }
  }
  if (appContext.ctx.err) {
    Sentry.captureException(appContext.ctx.err)
  }
  return { locale, serverBundle, serverService }
}

const withPageWrapper = (Component) => {
  const brigeInitProps = async (ctx: NextPageContext) => {
    const appProps = Component.getInitialProps ? await Component.getInitialProps(ctx) : {}
    const wrapAppProps = await FreecPublicPageWrapper.getInitialProps({ ctx })
    return { ...appProps, ...wrapAppProps }
  }
  const Wrapp = (props) => {
    return <FreecPublicPageWrapper {...props} Component={Component} />
  }
  Wrapp.getInitialProps = brigeInitProps
  return Wrapp
}

export default withPageWrapper
