import { useServerService } from '../components/FreecProvider'
import { RoleMeta } from '../type/RoleMeta'
import { TokenPayload } from '../type/TokenPayload'
import { X_AUTH_TOKEN } from '../utilities/Client'
import { getCookie } from '../utilities/Cookies'
import { readToken } from '../utilities/JWTToken'
import { getIsServer } from '../utilities/SSR'
import config from '../../../../config'
import { EnumUserType } from '../Enum/UserTypeEnum'
function getSecureRoleMeta(tokenInfo: TokenPayload): RoleMeta {
  const back_end_matrix = {
    [EnumUserType.CANDIDATE]: 'candidate',
    [EnumUserType.ADMIN]: 'admin', // supper_admin
    [EnumUserType.CONSULTANT]: 'consultant',
    [EnumUserType.RECRUITER]: 'company'
  }
  const roleCode = back_end_matrix[tokenInfo.user_type]
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  return {
    ...config.security.role_metas.find((meta) => meta.role === roleCode),
    is_super_admin: tokenInfo.user_type ? tokenInfo.user_type === 2 : false
  }
}

export const useJWTTokenPayload = () => {
  const serverService = useServerService()
  const token = getIsServer() ? (serverService ? serverService.getCookie(X_AUTH_TOKEN) : undefined) : getCookie(X_AUTH_TOKEN)
  if (!token) {
    return
  }
  const payload = readToken(token)
  return payload
}

export const useJWTRole = () => {
  const payload = useJWTTokenPayload()
  if (!payload) {
    return
  }
  const meta = getSecureRoleMeta(payload)
  return meta.role
}
export const useJWTIsLogin = () => {
  const payload = useJWTTokenPayload()
  return !!payload?.isCredentialValid
}
