import css from 'styled-jsx/css'

export const DefaultLayoutCompanyStyle = css.global`
  .company-layout .container {
    margin-top: var(--layout-header-height);
    min-height: -moz-calc(100vh - var(--layout-footer-height) - var(--layout-header-height));
    min-height: calc(100vh - var(--layout-footer-height) - var(--layout-header-height));
    background-color: var(--neutral);
  }
  .company-layout .header {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    background: #fff;
    height: 64px;
    display: flex;
    border-bottom: 1px solid #e8e8e8;
    padding: 0px 30px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 64px;
  }
  .company-layout .header.none-shadow {
    box-shadow: none;
    border-bottom: 1px solid #d9d9d9;
  }
  .company-layout .header .left-group,
  .company-layout .header .right-group {
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    align-items: center;
    height: 100%;
  }
  .company-layout .header .left-group .header-logo-img,
  .company-layout .header .right-group .header-logo-img {
    /* height: 34px; */
    display: inline-flex;
  }
  .company-layout .header .left-group .header-logo-img .logo-freec,
  .company-layout .header .right-group .header-logo-img .logo-freec {
    /* height: 34px;
    width: 51px; */
    display: inline-flex;
  }
  .company-layout .header .left-group .header-link-group,
  .company-layout .header .right-group .header-link-group {
    padding-left: 40px;
  }
  .company-layout .header-chat {
    position: relative;
  }
  .company-layout .header-chat::before {
    position: absolute;
    content: '';
    width: 32px;
    height: 32px;
    border-radius: 4px;
    top: -4px;
    left: -4px;
  }
  .company-layout .header-chat:hover::before {
    background-color: #f5f5f5;
  }
  .company-layout .header-chat:active path {
    fill: var(--primary);
  }
  .company-layout .header .left-group .header-link,
  .company-layout .header .right-group .header-link {
    font-size: 14px;
    color: #313543;
    margin-right: 40px;
    position: relative;
    text-decoration: none;
    font-weight: 500;
    cursor: pointer;
  }
  .company-layout .header .left-group .header-link:hover,
  .company-layout .header .right-group .header-link:hover {
    color: var(--primary);
  }
  .company-layout .header .left-group .header-link .tag-beta,
  .company-layout .header .right-group .header-link .tag-beta {
    padding: 2px 5px;
    border-radius: 2px;
    background-color: #ff4d4f;
    margin-left: 5px;
    transform: translateY(-1px);
    display: inline-block;
    font-size: 8px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    text-transform: uppercase;
  }

  .company-layout .header .left-group .header-link.active:before,
  .company-layout .header .right-group .header-link.active:before,
  .company-layout .header .left-group .header-link:hover:before,
  .company-layout .header .right-group .header-link:hover:before {
    position: absolute;
    content: '';
    height: 2px;
    width: 100%;
    background-color: var(--primary);
    bottom: 0;
    left: 0;
  }
  .company-layout .header .left-group .header-link-group {
    display: flex;
  }
  .company-layout .header .right-group {
    margin-left: auto;
  }

  .company-layout .header .right-group .header-sign-in-up {
    margin-right: 32px;
  }
  .company-layout .header .right-group .header-sign-in-up {
    margin-right: 32px;
    display: flex;
  }
  .company-layout .header .right-group .btn-login {
    color: #141921;
    font-weight: 500;
    padding: 5px 16px;
    border: 1px solid #d1d7dc;
    background-color: #fff;
    font-size: 14px;
    border-radius: 4px;
    margin-right: 8px;
    height: 32px;
    display: flex;
    align-items: center;
    white-space: nowrap;
  }
  .company-layout .header .right-group .btn-register {
    color: #fff;
    font-weight: 500;
    padding: 5px 16px;
    border: 1px solid var(--primary);
    background-color: var(--primary);
    font-size: 14px;
    border-radius: 4px;
    height: 32px;
    display: flex;
    align-items: center;
    white-space: nowrap;
  }
  .company-layout .header .right-group .header-employer {
    padding-left: 16px;
    border-left: 1px solid #e8e8e8;
  }
  .company-layout .header .right-group .header-employer .btn {
    padding: 0;
    border: 0 none;
    background-color: transparent;
    cursor: pointer;
  }
  .company-layout .header .right-group .header-employer .btn .icon-left {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: 0 12px 0 0;
  }
  .company-layout .header .right-group .header-employer .btn-info .title {
    font-size: 14px;
    font-weight: bold;
    text-align: left;
    text-transform: uppercase;
    color: var(--primary);
    line-height: 1.8;
  }
  .company-layout .header .right-group .header-employer .btn-info .sub-title {
    font-size: 12px;
    text-align: left;
    color: #262626;
    line-height: 1;
  }
  .company-layout .header .ant-dropdown-trigger > .anticon.anticon-down,
  .ant-dropdown-link > .anticon.anticon-down {
    font-size: inherit;
    vertical-align: -0.125em;
  }
  .company-layout .header .right-group .header-employer .btn .icon-right {
    margin-left: 16px;
    line-height: 1;
  }

  @media screen and (max-width: 768px) {
    .company-layout .header.header-hide-mobile {
      display: none;
    }
  }
`
