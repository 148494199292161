import css from 'styled-jsx/css'

export const ColorsStyle = css.global`
  body .color-main {
    color: var(--primary);
  }

  body .color-facebook {
    color: #1877f2;
  }

  body .color-linkedin {
    color: #3272ae;
  }

  body .new-color-text {
    color: #141921;
  }

  body .color-grey-9,
  .color-link {
    color: var(--grey-9);
  }

  body .color-grey-8 {
    color: var(--grey-8);
  }

  body .color-grey-7 {
    color: var(--grey-7);
  }

  body .color-black {
    color: var(--black);
  }

  body .color-white-bg {
    background-color: var(--white);
  }
`
