export const app_IsMobile = (req) => {
  const userAgent = req.headers['user-agent']
  return !!userAgent?.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i)
}
export const app_GetOS = (req) => {
  const userAgent = req.headers['user-agent']
  if (userAgent?.indexOf('Mac')) {
    return 'iOS'
  }
  return userAgent?.match(/Android/i) ? 'AndroidOS' : 'Unknown'
}
